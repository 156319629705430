<template>
    <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="initialize" :configurable="false" :queryParam="queryParam">
        <el-table slot="elList" ref="carrierListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" @current-change="handleCurrentChange" highlight-current-row>
            <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
            <el-table-column v-for="(col,index)  in dataSource.ColDefs.BodyFieldParams"
                             :key="index"
                             :prop="col.FieldName"
                             :label="col.DisplayName"
                             :render-header="bindFilter(queryParam,col)"
                             :fixed="index==0&&(!config||!config.isDetailDisplay)"
                             :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                             v-if="col.Visible">
                <template slot-scope="scope">
                    <el-button v-if="col.FieldName==='MemberCode'" type="text" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</el-button>
                    <span v-else>  {{ scope.row[col.FieldName] }}</span>
                </template>
            </el-table-column>
        </el-table>
    </fixed-list>
</template>
<script>
    export default {
        mounted() {
            this.initialize();//加载表格数据
        },
        data() {
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    params: {}
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0,
                },
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
            },
            selectCondition: {
            }
        },
        methods: {
            onChangeEditDataSource: function (data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
            onDataSourceChange(ds) {
                var _this = this;
                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            handleCurrentChange: function (val) {
                this.$emit("onSelectedRow", val);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            initialize: function () {
                var _this = this;
                delete _this.queryParam.params.WarehouseNo;
                delete _this.queryParam.params.MemberType;
                if (_this.config.WarehouseNo != undefined && _this.config.WarehouseNo != null && _this.config.WarehouseNo != "") {
                    _this.queryParam.params.WarehouseNo = _this.config.WarehouseNo;
                }
                if (_this.config.MemberType != undefined && _this.config.MemberType != null && _this.config.MemberType != "") {
                    _this.queryParam.params.MemberType = _this.config.MemberType;
                }
                if (_this.selectCondition) {
                    _this.Utils.extend(_this.queryParam.params, _this.selectCondition);
                }
                this.$ajax.query("omsapi/memberinfo/searchcarrierbycustomercode", "post", _this.queryParam, (result) => {
                    _this.dataSource = result;
                });
            },

        },

    }
</script>
